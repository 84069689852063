<template>
  <div 
    class="item-wrapper" 
  >
    <div class="item-top">
      <div 
        class="preview" 
        @click="setProductCategory"
      >
        <img 
          v-if="categories && categories.length"
          :src="categoryImg" 
          class="img"
        >
        <img 
          v-else
          :src="imgDomain + item.img_url" 
          class="img"
        >
      </div>
    </div>
    <div class="item-bottom">
      <div class="price-container">
        <div 
          v-if="categories && categories.length"
          class="desc category-desc" 
        >
          {{ item }}
        </div>
        <div 
          v-else
          class="desc" 
          @click="setProductCategory"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="desc tag">
        <b>
          {{ item.type }}
        </b>
      </div>
      <div 
        class="price desc"
        @click="setProductCategory"
      >
        <span 
          v-if="item.old_price != '0.00'"
          class="discount" 
        >
          <span class="currency">
            {{ currency }}
          </span>
          {{ item.old_price }}
        </span>
        <span>
          <span class="currency">
            <b>{{ currency }}</b>
          </span> 
          <b>{{ item.price }}</b>
        </span>
        <span 
          v-if="discountPercentage > 0" 
          class="percent" 
        >
          <b>-{{ discountPercentage }}%</b>
        </span>
      </div>
      <button 
        class="button"  
        @click="$parent.addToCart(item.id)"
      >
        <span 
          v-if="$parent.addToCartChosenItem && $parent.addToCartChosenItem == item.id && !$parent.error" 
        >
          {{ $t('Added') }}
        </span>
        <span v-if="!$parent.addToCartChosenItem || $parent.addToCartChosenItem != item.id">{{ $t('Add to cart') }}</span>
      </button>
      <transition name="slide">
        <div 
          v-if="$parent.error" 
          class="error-desc desc red"
        >
          {{ $parent.error }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: {
		item: {
			type: [String, Object],
			required: true
		},
		sliderIsClickble: {
			type: Boolean,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
		categories: {
			type: Array,
			required: true
		},
	},
	data: function() {
		return {
			imgDomain: '',
      categoryImg: ''
		}
	},
	computed: {
		discountPercentage() {
			if (parseFloat(this.item.old_price) > 0) {
				const oldPrice = parseFloat(this.item.old_price);
				const newPrice = parseFloat(this.item.price);
				const discount = ((oldPrice - newPrice) / oldPrice) * 100;
				return discount.toFixed(0);
			} else {
				return 0;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;

    if (this.categories && this.categories.length) {
      const cleanItem = this.item.replace(/\s+/g, '');
      this.categoryImg = require(`@/images/classes/${cleanItem.toLowerCase()}.png`);
    }
    
	},
	methods: {
		goToSliderItem(item) {
			this.$emit('goToProduct', item);
		},
		setProductCategory() {
			let self = this;
      if (this.categories && this.categories.length) {
        setTimeout(()=> {
          if (self.sliderIsClickble == true) {
            if (self.categories && self.categories.length) {
              self.$emit('setProductCategory', this.item)
            }
          }
        }, 200)
      } else {
        this.goToSliderItem(this.item);
      }
		},
	},
}
</script>
