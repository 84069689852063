<template>
  <div class="modal product-modal">
    <div 
      class="overlay"
      @click="$parent.closeProductModal"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              class="close" 
              src="./../images/close.svg"
              @click="$parent.closeProductModal"
            >
            <div class="desc big">
              <b>{{ $parent.productPageItem.title }}</b>
            </div>
            <div class="preview">
              <img 
                :src="$parent.imgDomain + $parent.productPageItem.img_url" 
                class="img"
              >
            </div>
            <div class="text">
              <div class="left">
                <div class="title small price">
                  <span class="currency">
                    {{ $parent.currency }}
                  </span>
                  {{ $parent.productPageItem.price }}
                </div>
              </div>
              <div class="right">
                <button 
                  class="button"  
                  @click="$parent.addToCart($parent.productPageItem.id)"
                >
                  <span>{{ $t('Add to cart') }}</span>
                </button>
              </div>
            </div>
            <transition name="slide">
              <div 
                v-if="$parent.addToCartChosenItem" 
                class="desc green"
              >
                {{ $t('Added') }}
              </div>
            </transition>
            <transition name="slide">
              <div 
                v-if="$parent.error" 
                class="error-desc desc red"
              >
                {{ $t($parent.error) }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductModal',
  props: [],
  data: function() {
    return {
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>