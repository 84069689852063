<template>
  <div class="menu">
    <div 
      v-if="$parent.isAuth"
      class="cash" 
    >
      <img 
        src="./../images/wallet.svg" 
        class="img"
      >
      <div class="desc">
        <span class="currency">{{ $parent.currency }}</span>
        {{ balance }}
      </div>
      <div 
        class="" 
        @click="$parent.openTopup"
      >
        <img 
          src="./../images/plus.svg" 
          class="img"
        >
      </div>
    </div>
    <button 
      v-if="$parent.isAuth" 
      :class="['icon icon-cart round', {'gray': !cartContents.length}]"
      @click="$parent.openCart" 
    >
      <img 
        src="./../images/cart.svg" 
        class="img"
      >
      <div 
        v-if="cartContents.length"
        class="indicator"
      >
        {{ cartContents.length }}
      </div>
    </button>
    <router-link 
      v-if="$parent.isAuth"
      to="/profile/settings" 
      class="button link-container dropdown-open round" 
    >
      <img 
        src="./../images/user.svg" 
        class="img"
      >
    </router-link>
    <div 
      v-else
      class="link-container" 
    >
      <div 
        class="button" 
        @click="openSignIn"
      >
        <span>{{ $t('Sign In') }}</span>
      </div>
    </div>
    <div 
      v-if="curr"
      class="select-container" 
    >
      <Select2 
        v-model="curr"
        class="select2 single currency-select" 
        :placeholder="curr"
        :options="$parent.currencyOptions"
        @select="selectCurr($event)"
      />
    </div>
    <div 
      v-if="currentLanguage"
      class="select-container language-select-container" 
    >
      <Select2 
        v-model="language"
        class="select2 single currency-select" 
        :options="languages"
        :placeholder="currentLanguageName"
        @select="selectLanguage($event)"
      />
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations, mapGetters, mapActions} from "vuex";
import Select2 from 'vue3-select2-component';
export default {
	name: 'Menu',
	components: {
		Select2
	},
	props: {
		cartContents: {
			type: Array,
			required: true
		},
		currencyCode: {
			type: String,
			required: true
		},
		isAuth: {
			type: Boolean,
			required: true
		},
	},
	data: function() {
		return {
			curr: '',
			balance: '0.00'
		}
	},
	computed: {
		...mapState({
			languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
			currentLanguage: state => state.app.currentLanguage,
		}),
		...mapGetters('app', ['currentLanguageName']),
		language: {
			set(langId) {
				this.changeLocale(langId);
			},
			get() {
				return this.currentLanguage;
			}
		}
	},
	watch: {
		currencyCode(newValue) {
			this.curr = newValue
		},
		isAuth() {
			this.getBalance();
		},
	},
	mounted() {
		this.curr = this.currencyCode
		this.getBalance();
	},
	methods: {
		getBalance() {
			if (this.$parent.isAuth) {
				this.$http.get(process.env.VUE_APP_API + 'user/profile')
				.then((res) => {
					this.balance = res.data.balance;
				})
				.catch((res) => {
				if(res.response.status === 401) {
					this.$parent.openSignIn();
				}
				})
			}
		},
		selectCurr(event) {
			this.curr = event.text;
			this.changeCurrency(event.text)
			localStorage.setItem("currency", event.text);
		},
		selectLanguage(event) {
			this.changeLocale(parseInt(event.id));
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
		openSignIn() {
			this.$parent.openSignIn();
		},
		openSignUp() {
			this.$parent.openSignUp();
		},
		...mapMutations('app' , ['setCurrentLanguage']),
		...mapActions('app', ['changeLocale'])
	},
}
</script>
